import Carrousel from "../ProjectCarrousel/Carrousel";
import {useTranslation} from "react-i18next";
import {MutableRefObject} from "react";


const MyProjects = () =>{
    const {t} = useTranslation();
    return (
        <div className={"w-full text-left"}>
            <Carrousel/>
        </div>
    )
}

export default MyProjects