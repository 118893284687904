import {useTranslation} from "react-i18next";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import NewGrid from "../newGrid/NewGrid";
import DarkLightSwitch from "../DarkLightSwitch/DarkLightSwitch";
import AboutMe from "./AboutMe";
import MyProjects from "./MyProjects";
import Studies from "./Studies";
import OtherData from "./OtherData";
import WorkExperience from "./WorkExperience";
import {useRef} from "react";
import Section from "./Section";
import ContactForm from "../ContactForm/ContactForm";
import Stack from "./Stack";
import TechProgress from "../TechProgress/TechProgress";
import Skills from "./Skills";

type Props = {
    darkMode: boolean
    openModal: () => void
    toggleDarkMode: () => void
}

const Presentation = (props: Props) => {
    const {t} = useTranslation();
    const aboutMeRef = useRef(null);
    const stackRef = useRef(null);
    const myProjectsRef = useRef(null);
    const studiesRef = useRef(null);
    const workExperienceRef = useRef(null);
    const otherDataRef = useRef(null);
    const skillsRef = useRef(null);
    return (
        <div className={"flex flex-col items-center w-full overflow-hidden"}>
            <div className={"flex justify-end pr-2 w-full gap-4  h-16"}>
                <DarkLightSwitch darkMode={props.darkMode} toggleDarkMode={props.toggleDarkMode}/>
                <LanguageSwitcher/>
            </div>

            <div className={"flex flex-col w-full ml-24 mr-24 2xl:max-w-[1536px] gap-1  pl-2 pr-2 "}>
                <div className={"flex justify-center  w-full"}>
                    <NewGrid aboutMeRef={aboutMeRef} stackRef={stackRef} myProjectsRef={myProjectsRef}
                             studiesRef={studiesRef} workExperienceRef={workExperienceRef}
                             otherDataRef={otherDataRef} skillsRef={skillsRef}/>
                </div>
            </div>
            <div className={"flex flex-col items-center w-full mt-4 sm:mt-6"}>
                <div ref={aboutMeRef} className={"w-full"}>
                    <AboutMe openModal={props.openModal}/>
                </div>
                <div className={"flex flex-col items-center w-full 2xl:max-w-[1536px] pl-4 pr-4 gap-2"}>
                    <div ref={myProjectsRef} className={"w-full"}>
                        <Section title={t("projects.myProjects")} noPaddingLeft={true}>
                            <MyProjects/>
                        </Section>
                    </div>
                    <div ref={studiesRef} className={"w-full"}>
                        <Section title={t('studies.title')}>

                            <Studies/>
                        </Section>
                    </div>
                    <div ref={stackRef} className={"w-full"}>
                        <Section title={t('stack.title')}>
                            <Stack/>
                        </Section>
                    </div>
                    <div ref={skillsRef} className={"w-full"}>
                        <Section title={t("skills.title")}>
                            <Skills/>
                        </Section>
                    </div>
                    <div ref={workExperienceRef} className={"w-full"}>
                        <Section title={t('workExperience.title')}>
                            <WorkExperience/>
                        </Section>
                    </div>
                    <div ref={otherDataRef} className={"w-full"}>
                        <Section title={t('otherData.title')}>
                            <OtherData/>
                        </Section>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Presentation