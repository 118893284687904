import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCertificate} from "@fortawesome/free-solid-svg-icons";


const Studies = () =>{
    const {t} = useTranslation();




    return (

                <ul>
                    <li>
                        • <a className={"font-bold"}>{t('studies.uni')}</a>
                        <ul className={"pl-4"}>
                            <li className={"dark:text-gray-300"}>
                                <FontAwesomeIcon icon={faCertificate} color={"#ddd04b"}/> {t('studies.tfgM')}
                            </li>
                        </ul>
                    </li>
                    <li>
                        • {t('studies.bach')}
                    </li>
                </ul>

    )
}

export default Studies