import {useTranslation} from "react-i18next";
import GridPanel from "./GridPanel";
import {MutableRefObject, useState} from "react";
import {isSmallerThanSize} from "../../sizes/sizes";

type Props = {
    aboutMeRef: MutableRefObject<any>
    myProjectsRef: MutableRefObject<any>
    studiesRef: MutableRefObject<any>
    workExperienceRef: MutableRefObject<any>
    otherDataRef: MutableRefObject<any>
    stackRef: MutableRefObject<any>
    skillsRef: MutableRefObject<any>
}

const NewGrid = (props: Props) => {
    const {t} = useTranslation();
    const [hoverCell, setHoverCell] = useState<number>(-1)
    const setActCell = (n: number) => setHoverCell(n)
    const isSm = isSmallerThanSize('sm')


    return (
        <div style={{fontFamily: "Orbitron", textShadow: "0.5px 0.4px #000000"}}
            className={"text-2xl sm:text-3xl select-none grid gap-2 sm:gap-4 w-full grid-cols-[1fr_1fr] " +
                "sm:grid-cols-9 sm:grid-rows-[1.5fr_1.5fr_0.8fr_0.8fr] grid-rows-[2fr_0.7fr_0.7fr_0.7fr_0.7fr_0.7fr]"}>
            <GridPanel fadeDir={isSm?"fade-right":"fade-down-right"}
                onClick={() => {
                props.aboutMeRef.current.scrollIntoView()
            }}
                       id="Sobre mí" cellNum={0} hoverCell={hoverCell} setHoverCell={setActCell}
                className={"col-start-1 sm:col-end-5 col-end-3 row-start-1 sm:row-end-3  items-center flex flex-col lg:gap-2 p-4"}>
                <div className={"flex w-full gap-2 items-center"}>
                    <img src={"/img/fotoSq.png"} className={"col-start-1 col-end-2 lg:h-36 sm:h-24 h-20 rounded-2xl"}/>
                    <div className={"text-left w-full flex flex-col justify-center mt-4"}>
                        <p className={"font-bold text-sm sm:text-[11px] md:text-[16px] lg:text-xl"}>{t("aboutMe.short")}</p>
                    </div>
                </div>
                <div className={"text-left w-full"}>
                    <a className={"font-bold text-lg sm:text-[15px] md:text-[20px] lg:text-[27px] "}>Jaime José Corral García</a>
                </div>


            </GridPanel>
            <GridPanel background={"/img/studies.png"}  fadeDir={isSm?"fade-right":"fade-down"}
                onClick={() => {
                props.studiesRef.current.scrollIntoView()
            }}
                       id="Estudios" cellNum={1} hoverCell={hoverCell} setHoverCell={setActCell}
                       className={"sm:col-start-5 col-start-1 sm:col-end-8 col-end-2 row-start-3 sm:row-start-1 bg-bgDark flex flex-col items-start relative overflow-hidden"}
                       style={{}}>
                <div className={"absolute w-full h-full flex justify-start pl-8 items-center"}>
                    <a className={"font-bold text-xl sm:text-xl md:text-2xl lg:text-3xl mb-4"}>{t("studies.title")}</a>
                </div>
            </GridPanel>
            <GridPanel background={"/img/skills.png"}  fadeDir={isSm?"fade-left":"fade-down"}
                       onClick={() => {
                           props.skillsRef.current.scrollIntoView()
                       }}
                       id="Conocimientos" cellNum={1} hoverCell={hoverCell} setHoverCell={setActCell}
                       className={"sm:col-start-5 col-start-2 sm:col-end-8 col-end-3 row-start-3 " +
                           "sm:row-start-2 bg-bgDark flex flex-col items-start relative overflow-hidden"}
                       style={{}}>
                <div className={"absolute w-full h-full flex justify-start  sm:pl-2 items-center"}>
                    <a className={"font-bold text-xl sm:text-xl md:text-2xl lg:text-3xl mb-4"}>{t("skills.title")}</a>
                </div>
            </GridPanel>
            <GridPanel  fadeDir={isSm?"fade-left":"fade-down-left"}
                onClick={() => {
                props.stackRef.current.scrollIntoView()
            }}
                       id={"Tecnologias"} cellNum={2} hoverCell={hoverCell} setHoverCell={setActCell}
                       className={"sm:col-start-8 col-start-1 col-end-3 sm:col-end-10 sm:row-start-1 sm:row-end-3 row-start-4 " +
                           "row-end-4  bg-bgDark overflow-hidden relative"}
                       style={{}}
                       background={"/img/tech.png"}
            >
                <div className={"absolute w-full h-full flex justify-start sm:justify-center sm:items-center sm:p-0 pl-2 "}>
                    <a className={"font-bold text-2xl sm:text-[17px] md:text-xl lg:text-2xl xl:text-3xl"}>{t("stack.title")}</a>
                </div>

            </GridPanel>
            <GridPanel background={"/img/codeProjects.png"} fadeDir={isSm?"fade-right":"fade-right"}
                onClick={() => {
                props.myProjectsRef.current.scrollIntoView()
            }}
                       id={"Proyectos"} cellNum={3} hoverCell={hoverCell} setHoverCell={setActCell}
                       className={"col-start-1 col-end-3 sm:col-end-6 row-start-2 row-end-2 sm:row-start-3 sm:row-end-5 bg-bgDark overflow-hidden relative"}
                       style={{}}>
                <div className={"absolute w-full h-full text-center p-2 pt-4"}>
                    <a className={"font-bold text-2xl md:text-3xl"}>{t("projects.title")}</a>
                </div>
            </GridPanel>
            <GridPanel background={"/img/workI.png"}  fadeDir={"fade-left"}
                onClick={() => {
                props.workExperienceRef.current.scrollIntoView()
            }}
                       id={"Experiencia laboral"} cellNum={4} hoverCell={hoverCell} setHoverCell={setActCell}
                       className={"col-start-1 col-end-3 sm:col-start-6 sm:col-end-10 row-start-5 sm:row-start-3 " +
                           "sm:row-end-4 bg-bgDark relative overflow-hidden"}
                       style={{}}>
                <div className={"absolute w-full h-full flex p-2 pl-4"}>
                    <a className={"font-bold text-xl md:text-2xl"}>{t("workExperience.title")}</a>
                </div>
            </GridPanel>
            <GridPanel background={"/img/otherData.png"} fadeDir={isSm?"fade-left":"fade-left"}
                onClick={() => {
                props.otherDataRef.current.scrollIntoView()
            }}
                       id={"Otros datos"} cellNum={5} hoverCell={hoverCell} setHoverCell={setActCell}
                       className={"overflow-hidden relative col-start-1 col-end-3 sm:col-start-6 sm:col-end-10 " +
                           "row-start-6 sm:row-start-4 sm:row-end-4 bg-bgDark"}
                       style={{}}>
                <div className={"absolute w-full h-full  flex p-2 pt-0 pl-4"}>
                    <a className={"font-bold text-[17px] lg:text-2xl"}>{t("otherData.title")}</a>
                </div>
            </GridPanel>
        </div>
    )
}

export default NewGrid