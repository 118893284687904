import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {SizeProp} from "@fortawesome/fontawesome-svg-core";

export const timeoutInMS = (delay: number) => {
    return new Promise(res => setTimeout(res, delay));
}

const CopyMail = (props:{iconSize?:SizeProp}) => {
    const [openAlert, setOpenAlert] = useState(false)
    const {t} = useTranslation()
    const onClick = async () => {
        await navigator.clipboard.writeText("jaimejcorralgarcia@gmail.com")
        setOpenAlert(true)
        await timeoutInMS(2500)
        setOpenAlert(false)
    }

    return (
        <div onClick={onClick}
             className={"inline-block whitespace-nowrap cursor-pointer relative hover:underline"}>
            {openAlert &&
                <div onClick={(e) => {
                    e.stopPropagation()
                    setOpenAlert(false)
                }
                }
                     className={"right-[-5%] bottom-[90%] absolute w-fit bg-gray-300 no-underline dark:bg-gray-600 border-2 border-black dark:border-white p-2 rounded-xl"}>
                    <div>{t("common.mailCopied")}</div>
                </div>}
            jaimejcorralgarcia@gmail.com
            <div
                className={"ml-1 inline"}><FontAwesomeIcon size={props.iconSize?props.iconSize:"sm"} icon={faCopy}/>
            </div>
        </div>
    )
}

export default CopyMail