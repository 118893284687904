import SubSection from "./SubSection";
import TechProgress, {TechProgressProps} from "../TechProgress/TechProgress";
import {useTranslation} from "react-i18next";

const Stack = () => {
    const {t} = useTranslation()
    let tech:TechProgressProps[]=[
        {
            name: "Java",
            icon: "/img/techStack/java.png",
            progressPercent: 70
        },
        {
            name: "Maven",
            icon: "/img/techStack/maven.png",
            progressPercent: 55
        },
        {
            name: "SpringBoot",
            icon: "/img/techStack/springBoot.png",
            progressPercent: 60
        },
        {
            name: "Python",
            icon: "/img/techStack/python.png",
            progressPercent: 60
        },
        {
            name: "Go",
            icon: "/img/techStack/go.png",
            progressPercent: 70
        },
        {
            name: "JavaScript",
            icon: "/img/techStack/js.png",
            progressPercent: 70
        },
        {
            name: "TypeScript",
            icon: "/img/techStack/ts.png",
            progressPercent: 60
        },
        {
            name: "Tailwind",
            icon: "/img/techStack/tailwind.png",
            progressPercent: 70
        },
        {
            name: "React",
            icon: "/img/techStack/react.png",
            progressPercent: 75
        },
        {
            name: "Angular",
            icon: "/img/techStack/angular.png",
            progressPercent: 60
        },
        {
            name: "Docker",
            icon: "/img/techStack/docker.webp",
            progressPercent: 60
        },
        {
            name: "Kubernetes",
            icon: "/img/techStack/kubernetes.png",
            progressPercent: 60
        },
        {
            name: "Git",
            icon: "/img/techStack/git.png",
            progressPercent: 60
        },
        {
            name: "SQL",
            icon: "/img/techStack/sql.png",
            progressPercent: 70
        },
        {
            name: "MongoDB",
            icon: "/img/techStack/mongo.svg",
            progressPercent: 60
        },
    ]
    return(<div className={"w-full text-left"}>
        <p>{t("stack.p1")}</p>
        <p>{t("stack.p2")}:</p>
        <div className={"flex flex-wrap gap-2 max-w-[900px] mt-2"}>
            {tech.map((x, i) =>
                <TechProgress key={i} icon={x.icon} name={x.name} progressPercent={x.progressPercent}/>)}
        </div>
    </div>)
}

export default Stack