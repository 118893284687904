import React, {useEffect, useState} from 'react';
import './App.css';
import Presentation from "./components/presentation/Presentation";
import 'aos/dist/aos.css'
import Aos from "aos";
import ContactForm from "./components/ContactForm/ContactForm";

function App() {
    const [darkMode, setDarkMode] = useState(true)
    const [openModal, setOpenModal] = useState(false)
    const toggleDarkMode = ()=>{
        setDarkMode(!darkMode)
    }

    useEffect(()=>{
        Aos.init()
    }, [])
  return (
    <div className={`App pb-8 dark:bg-bgDark bg-bgLight dark:text-mainTextDark text-mainTextLight min-h-[100dvh] ${darkMode?'dark':''}`}>
        {openModal&&<ContactForm closeModal={()=>setOpenModal(false)}/>}
        <Presentation openModal={()=>setOpenModal(true)} toggleDarkMode={toggleDarkMode} darkMode={darkMode}/>
    </div>
  );
}

export default App;
