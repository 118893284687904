import NestedList, {NestedListT} from "./common/NestedList";
import {useTranslation} from "react-i18next";

const Skills = () => {
    const {t} = useTranslation()
    const paragraphs: NestedListT = [
        t("skills.captions.requirementsAnalysis"),
        t("skills.captions.softwareArchitecture"),
        t("skills.captions.backendFrontendProgramming"),
        t("skills.captions.enterpriseApplications"),
        t("skills.captions.configurationManagement"),
        t("skills.captions.developmentMethodologies"),
        t("skills.captions.designPatterns"),
        t("skills.captions.databaseSystems"),
        [
            t("skills.captions.relationalDatabases"),
            t("skills.captions.mongoDb")
        ],
        t("skills.captions.programmingLanguagesTyping"),
        t("skills.captions.versionControlGitGitFlow"),
        t("skills.captions.continuousIntegrationJenkinsGithub"),
        t("skills.captions.testing"),
        [
            t("skills.captions.unitIntegrationTests"),
            t("skills.captions.testingTechniquesMocking")
        ],
        t("skills.captions.deploymentInfrastructure"),
        [
            t("skills.captions.containerizationDocker"),
            t("skills.captions.kubernetesDeployments"),
            [
                t("skills.captions.clusterManagement")
            ]
        ],
        t("skills.captions.networking"),
        [
            t("skills.captions.networkProtocols"),
            t("skills.captions.ciscoPacketTracerCLI"),
            t("skills.captions.networkConfigurationVlans"),
        ]
    ];

    return (
        <div className={"w-full text-left"}>
            <p>
                {t("skills.p1")}
            </p>
            <p>{t("skills.p2")}:</p>
            <div className={"pl-2"}>
                <NestedList paraphs={paragraphs}/>
            </div>

        </div>
    )
}

export default Skills