import React, {ChangeEvent, FormEvent, useEffect, useRef, useState} from "react";
import {faCopy, faClose, faSpinner, faCheck, faEnvelope, faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import emailjs from '@emailjs/browser';
import {Options} from "@emailjs/browser/es/types/Options";
import {useTranslation} from "react-i18next";
import CopyMail, {timeoutInMS} from "../presentation/common/CopyMail";
import {FontawesomeObject, IconDefinition} from "@fortawesome/fontawesome-svg-core";
import ContactFormInput, {FormInput} from "./ContactFormInput";
import ContactInputFormWithLabelAndConditions from "./ContactInputFormWithLabelAndConditions";

const keepIconWithWord = (text: string, icon: IconDefinition, iconColor?: string) =>{
    const splitString = text.split(" ")
    return (
        <a>{splitString.slice(0, splitString.length - 1).join(" ") + " "}
            <a className={"inline-block break-keep"}>{splitString.slice(splitString.length - 1, splitString.length)}
                <FontAwesomeIcon className={"ml-1"} color={iconColor} size={"lg"} icon={icon}/></a>
        </a>
    )
}

const SendingMail = () => {
    const {t} = useTranslation()
    return (
        <div className={"flex w-fit gap-1"}>
            <a>{t("contactForm.sendingMail")}</a>
            <div className={"animate-spin"}><FontAwesomeIcon size={"lg"} icon={faSpinner}/></div>
        </div>
    )
}

const MailSent = () => {
    const {t} = useTranslation()


    return (
        <p className={"flex flex-col w-fit items-start text-left gap-1"}>
            {keepIconWithWord(t("contactForm.messageSent"), faCheck, "#81bf68")}
            {keepIconWithWord(t("contactForm.confirmationMailSent"), faEnvelope)}
            <a>{t("contactForm.thankYouForYourMessage")}</a>
        </p>
    )
}

const MailWrong = () => {
    const {t} = useTranslation()
    return (
        <p className={"flex flex-col w-fit items-start text-left"}>
            <a>{t("contactForm.errorSendingMessage")} <FontAwesomeIcon size={"lg"} color={"#bf6868"}
                                                                                        icon={faCircleExclamation}/></a>
            <p>{t("contactForm.pleaseContactByMail")}: <CopyMail/></p>
        </p>
    )
}

const ContactForm = (props: { closeModal: () => any }) => {
    const {t, i18n} = useTranslation();
    const [showModal, setShowModal] = useState(false)
    const [winScroll, setWinScroll] = useState(window.scrollY)
    const form = useRef<HTMLFormElement | null>(null);
    const [name, setName] = useState("")
    const [mail, setMail] = useState("")
    const [message, setMessage] = useState("")
    const [remarkInvalidAndEmpty, setRemarkInvalidAndEmpty] = useState(false)
    const remarkTime = async () => {
        setRemarkInvalidAndEmpty(true)
        await timeoutInMS(10000)
        setRemarkInvalidAndEmpty(false)
    }
    type MailSentStatus = "inForm" | "sending" | "sentOk" | "errorSending"
    const [mailSendStatus, setMailSendStatus] = useState<MailSentStatus>("inForm")
    const nameValid = name.length>0
    const messageValid = message.length>0
    const mailRegex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
    const mailVaild = mailRegex.test(mail)

    const allValid = nameValid && messageValid && mailVaild

    const isLangEs = () => i18n.language === 'es'
    const sendEmail = (e: FormEvent, langEs = false) => {
        e.preventDefault();
        if(!allValid) {
            setRemarkInvalidAndEmpty(true)
            return;
        }
        setMailSendStatus("sending")
        if (!form.current)
            return

        emailjs.sendForm('service_x8r4r0t', langEs ? 'template_1cdl4va' : 'template_tr70j6e', form.current, {
            publicKey: 'CRd-64uG3TGThe-d0',

        })
            .then(
                () => {
                    setMailSendStatus("sentOk")
                },
                (error) => {
                    setMailSendStatus("errorSending")
                },
            );
        window.history.pushState({}, '', '/',)
    };

    useEffect(() => {
        const scrollPos = window.scrollY
        const onScroll = () => window.scrollTo({top: winScroll})
        window.addEventListener("scroll", onScroll)
        return () => {
            window.removeEventListener("scroll", onScroll)
            onScroll()
        }
    }, [])
    type condition = {text: string, icon: JSX.Element, value: boolean}

    const inputs:FormInput[] =
        [
            {
                text: t("contactForm.name"),
                label: "name",
                value: name,
                onChange: (n: string) => setName(n),
                textArea: false,
                fieldIsValid: nameValid,
                conditions: [
                    {
                        text: t("contactForm.writeYourName"),
                        icon: <FontAwesomeIcon icon={faCircleExclamation}/>,
                        value: name.length===0
                    },
                ]
            },
            {
                text: t("contactForm.mail"),
                label: "mail",
                value: mail,
                onChange: (n: string) => setMail(n.trim()),
                textArea: false,
                fieldIsValid: mailVaild,
                conditions: [
                    {
                        text: t("contactForm.writeYourMail"),
                        icon: <FontAwesomeIcon icon={faCircleExclamation}/>,
                        value: mail.length===0
                    },
                    {
                        text: t("contactForm.enterValidMail"),
                        icon: <FontAwesomeIcon color={"red"} icon={faCircleExclamation}/>,
                        value: !mailVaild && mail.length>0
                    },
                ]
            },
            {
                text:  t("contactForm.message"),
                label: "message",
                value: message,
                onChange: (n: string) => setMessage(n),
                textArea: true,
                fieldIsValid: messageValid,
                conditions: [
                    {
                        text:  t("contactForm.writeYourMessage"),
                        icon: <FontAwesomeIcon icon={faCircleExclamation}/>,
                        value: message.length===0
                    },
                ]
            },
        ]

    return (
        <div className={" fixed w-[100vw] h-[100vh] overflow-scroll  z-40 bg-black bg-opacity-75"}
             onClick={()=>mailSendStatus!=="sending"&&props.closeModal()}>
            <div
                className={"mt-6 sm:mt-[10vh]  justify-center  flex w-full"}>
                <div
                    onClick={(e) => e.stopPropagation()}
                     className={"w-fit bg-aboutMeBgLight dark:bg-bgDark ml-2 mr-2 sm:ml-8 sm:mr-8 mb-8 rounded-xl p-2 flex flex-col"}>
                    <div className={"flex justify-end"}>
                        {mailSendStatus!=="sending" &&
                            <a className={"cursor-pointer pr-2 pt-1"} onClick={props.closeModal}>
                            <FontAwesomeIcon size={"xl"} icon={faClose}/>
                        </a>}
                    </div>
                    <div className={"w-full flex flex-col items-center sm:mt-8 pt-0 p-4"}>
                        {mailSendStatus == "sending" && <SendingMail/>}
                        {mailSendStatus == "sentOk" && <MailSent/>}
                        {mailSendStatus == "errorSending" && <MailWrong/>}
                    </div>

                    {mailSendStatus == "inForm" &&
                        <div className={"sm:pl-8 sm:pr-8 pl-2 pr-2 pb-8"}>
                            <div  className={"text-left mt-4"}>
                                <p className={"text-xl sm:text-2xl font-bold light:text-black"}>{t("contactForm.title")}</p>
                                <div className={"text-md sm:text-lg inline-block"}>
                                    <a className={"mr-1"} >{t("contactForm.mailAdv")}:</a>
                                    <CopyMail/>
                                </div>
                            </div>
                            <div className={"flex w-full justify-center mt-4"}>
                                <form ref={form} onSubmit={(e) => sendEmail(e, isLangEs())}
                                      className={"dark:text-mainTextDark text-mainTextLight flex flex-col items-end gap-2 w-full"}>
                                    {inputs.map((x, i) =>
                                        <ContactInputFormWithLabelAndConditions contactFormInput={x} key={i} remarkInvalidAndEmpty={remarkInvalidAndEmpty}/>
                                    )}
                                    <input
                                        className={`mt-1 text-xl sm:w-fit w-full
                                        ${allValid?"cursor-pointer bg-contactFormSendButtonOkLight " +
                                            "dark:bg-contactFormSendButtonOkDark opacity-100":"opacity-40 cursor-not-allowed bg-red-700 " +
                                            "dark:bg-contactFormSendButtonWrongDark bg-contactFormSendButtonWrongLight"}  
                                        p-4 sm:p-2 pl-4 pr-4 rounded-xl font-bold text-white text-sm shadow shadow-black`}
                                        type="submit" value={t("contactForm.sendButton")}/>
                                </form>
                            </div>
                        </div>}


                </div>
            </div>
        </div>


    )
}

export default ContactForm