import React from "react";

type condition = {text: string, icon: JSX.Element, value: boolean}
export type FormInput = {
    text: string,
    label: string,
    value: string,
    textArea: boolean,
    fieldIsValid: boolean,
    onChange: (a: any) => any,
    conditions: condition[] }

const ContactFormInput = ({contactFormInput, remarkInvalidAndEmpty}:{contactFormInput:FormInput, remarkInvalidAndEmpty: boolean}) =>{
    return(
        contactFormInput.textArea ?
                <textarea rows={5} value={contactFormInput.value}
                          className={`shadow-black max-w-[500px] shadow-sm outline-none outline-2 focus:outline-blue-300 border-2 rounded w-full pl-1 pr-1  resize-none ${contactFormInput.fieldIsValid?"border-green-300":remarkInvalidAndEmpty?"border-red-400":""}`}
                          onChange={(e) => contactFormInput.onChange(e.target.value)}
                          name={contactFormInput.label}/>
                : <input value={contactFormInput.value} className={`shadow-black shadow-sm outline-none outline-2 focus:outline-blue-300 border-2 ${contactFormInput.fieldIsValid?"border-green-300":remarkInvalidAndEmpty?"border-red-400":""}  rounded w-full pl-1 pr-1 max-w-72`}
                         onChange={(e) => contactFormInput.onChange(e.target.value)}
                         type="text"
                         name={contactFormInput.label}/>
    )
}

export default ContactFormInput