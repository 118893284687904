import {useState} from "react";
import {faCircleArrowLeft, faCircleArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

type Button = {
    text: string,
    href: string,
    color: string,
    pulse: boolean
}

export type Item = {
    name: string,
    background: string
    buttons: Button[]
    captions: string[]
    stackImgs: string[]
}

type CarrouselItemProps = {
    item: Item
}

const CarrouselItem = (props: CarrouselItemProps) => {
    type Props = {
        button: Button
    }

    const ButtonG = (props: Props) => {
        return (
            <button style={{backgroundColor: props.button.color}}
                    onClick={() => window.open(props.button.href, '_blank')}
                    className={`hover:brightness-[1.25] bg-blue-400 rounded-xl p-1 pl-2 pr-2 font-bold text-lg ${props.button.pulse?"animate-bounce":""}`}>
                {props.button.text}
            </button>
        )
    }
    return (
        <div
            className={"w-full h-full flex gap-4 relative"}>
            <div className={"absolute w-full h-full  blur-sm overflow-hidden"}>
                <img className={"w-full h-full object-cover object-center absolute top-0 left-0"}
                     alt="" src={props.item.background}/>
            </div>
            <div className={"h-full w-full absolute"}>
                <div className={"flex flex-col justify-between w-full gap-2 h-full pt-2"}>
                    <h1 className={"text-3xl font-bold pl-2"}>{props.item.name}</h1>
                    <div className={"flex justify-center items-center w-full h-full pl-2 pr-2"}>
                        <div
                            className={"p-2 rounded-2xl bg-gray-700 bg-opacity-80 font-bold text-xl sm:text-xl  lg:text-md  relative"}>
                            <ul>
                                {props.item.captions.map((x, i) =>
                                    <li key={i}>
                                        - <a className={""}>{x}</a>
                                    </li>
                                )}
                                <li>
                                    - <a>Stack: </a>{
                                    props.item.stackImgs.map((x, i) =>
                                        <img className={"w-8 sm:w-12 inline"}
                                             alt={x.substring(x.lastIndexOf("/") + 1, x.search("\\."))}
                                             key={i} src={`${x}`}/>)}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={"flex gap-2 justify-end p-2 mb-6"}>
                        {props.item.buttons.map((x, i) => <ButtonG button={x} key={i}/>)}
                    </div>
                </div>

            </div>

        </div>
    )
}

const Carrousel = () => {
    const {t} = useTranslation();
    const kanbanItem: Item = {
        name: t("projects.kanban.name"),
        background: "/img/projects/bannerKan.png",
        captions: [t("projects.kanban.caption1"), t("projects.kanban.caption2"), t("projects.kanban.caption3")],
        stackImgs: [
            "/img/stack/react.png",
            "/img/stack/tailwind.png",
            "/img/stack/go.png",
            "/img/stack/yjs.png",
            "/img/stack/mongo.png",
            "/img/stack/docker.png",
            "/img/stack/kubernetes.png",
        ],
        buttons: [{
            text: t("projects.commonButtons.moreInfo"),
            href: "https://kanban.jaimejcorral.com/about/",
            color: "#dc734c",
            pulse: true
        },
            {text: t("projects.commonButtons.goToApp"),
                href: "https://kanban.jaimejcorral.com/",
                color: "#ed821a",
                pulse: false
            }
        ]
    }

    const items = [kanbanItem]
    const [selected, setSelected] = useState(0)
    const move = (left?: boolean) => {
        if (left) {
            if (selected > 0)
                setSelected(selected - 1)
            else
                setSelected(items.length - 1)
        } else {
            if (selected < items.length - 1)
                setSelected(selected + 1)
            else
                setSelected(0)
        }
    }

    return (

        <div style={{fontFamily: "Lato"}} className={" w-full text-white h-[450px] landscape:h-80 landscape:lg:h-96 lg:h-96 relative rounded-2xl overflow-hidden"}>
            <CarrouselItem item={items[selected]}/>)
            <div id={"menu"} className={"flex gap-2 absolute bottom-0 left-[50%] translate-x-[-50%] select-none"}>
                <div className={"cursor-pointer"} onClick={() => move(true)}>
                    <FontAwesomeIcon icon={faCircleArrowLeft} color={"gray"}/>
                </div>
                <div>
                    {items.map((x, i) => <a key={i} className={"cursor-pointer"}
                                            onClick={() => setSelected(i)}>{i === selected ? '⚪' : '⚫'}</a>)}
                </div>
                <div className={"cursor-pointer"} onClick={() => move(false)}>
                    <FontAwesomeIcon icon={faCircleArrowRight} color={"gray"}/>
                </div>
            </div>
        </div>
    )
}

export default Carrousel