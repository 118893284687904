export type NestedListT = (string | NestedListT)[]

const NestedList = (props: { paraphs: NestedListT }) => {

    const buildList = (parap: NestedListT) =>
        parap.map((x, i) => {
            return (
                <li key={i}>
                    {typeof x === "string" && <a className={"flex gap-1"}><a>•</a> <a>{x}</a></a>}
                    {typeof x !== "string" &&
                        <ul className={"pl-4"}>
                            {buildList(x)}
                        </ul>
                    }
                </li>
            )})

    return (
        <ul>
            {buildList(props.paraphs)}
        </ul>
    )
}

export default NestedList