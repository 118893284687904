import {PropsWithChildren, Dispatch} from "react";

type Props = {
    id?: string,
    className?: string,
    style?: { [key: string]: string; },
    cellNum: number,
    hoverCell: number,
    setHoverCell: (n:number)=>any
    background?: string
    onClick?: ()=>any
    fadeDir?:string
}

const GridPanel = (props:PropsWithChildren<Props>) =>{
    return(

        <div data-aos-offset="-2000" data-aos={`${props.fadeDir}`}
            onClick={() => props.onClick ? props.onClick() : () => {
        }} style={{...props.style, zIndex: props.cellNum === props.hoverCell ? 3 : 2}}
             onMouseEnter={() => props.setHoverCell(props.cellNum)}
             onMouseLeave={() => props.setHoverCell(-1)}
             className={`hover:brightness-125 dark:bg-gridPanelDark bg-gridPanelLight rounded-2xl hover:cursor-pointer w-[100%] 
             hover:scale-105 hover:shadow-sm hover:shadow-white shadow-md shadow-gray-900 relative overflow-hidden 
             ${props.className}`}>
            <div className={"absolute w-full h-full  blur-sm overflow-hidden"}>
                {props.background && <img alt="" className={"w-full h-full object-cover object-center absolute top-0 left-0"}
                      src={props.background}/>}
            </div>
            {props.children}
        </div>
    )
}

export default GridPanel