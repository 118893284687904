import {faMoon} from "@fortawesome/free-solid-svg-icons";
import {faSun} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type Props = {
    darkMode: boolean
    toggleDarkMode: () => void
}

const DarkLightSwitch = (props: Props) => {
    const iconSize="xl"
    const iconDark = <FontAwesomeIcon color={"#eac605"} size={iconSize} icon={faSun}/>
    const iconLight = <FontAwesomeIcon size={iconSize} icon={faMoon}/>
    return (
        <div onClick={()=>{props.toggleDarkMode()}} className={"hover:cursor-pointer flex items-center h-full"}>
            {props.darkMode?iconLight:iconDark}
        </div>
    )
}

export default DarkLightSwitch