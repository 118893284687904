

export type TechProgressProps = {
    name: string,
    icon: string,
    progressPercent: number
}


const TechProgress= (props:TechProgressProps) =>{
    const iconSize = 8
    return(
        <div className={"flex flex-col w-36 sm:w-40 h-14 sm:h-16 border-black dark:border-white border-2 p-2 rounded-2xl "}>
            <div className={"flex h-[55%] w-full"}>
                <div id={"icon"}  className={" h-full w-1/5"}>
                    <img alt={props.name} className={"w-full h-full object-contain object-center"} src={props.icon} />
                </div>
                <div id={"name"}  className={"max-w-full pl-1 overflow-hidden"}>
                    <p className={"sm:text-lg text-sm w-full overflow-hidden overflow-ellipsis whitespace-nowrap font-bold dark:text-white text-black"}>{props.name}</p>
                </div>
            </div>
            <div className={"pl-2 pr-2 pt-2 h-[45%]"}>
                <div id={"progressbar"} className={"w-full border-2 border-black dark:border-white h-full rounded-xl overflow-hidden "}>
                    <div style={{width: props.progressPercent+"%"}} className={"h-full bg-gray-700 dark:bg-gray-100"}>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default TechProgress