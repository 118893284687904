import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {useState} from "react";
import ReactFlagsSelect from "react-flags-select";

const LanguageSwitcher = () =>{
    const { t, i18n } = useTranslation();
    const [selected, setSelected] = useState(i18next.resolvedLanguage=="es"?"ES":"GB");

    return(
        <div className={"text-gray-600 p-2 w-40"}>
            <ReactFlagsSelect
                selected={selected}
                onSelect={(code) => {
                    i18n.changeLanguage(code==="ES"?"es":"en").then(()=>{setSelected(code)})
                }}
                countries={["ES", "GB"]}
                customLabels={{ GB: "English", ES: "Español" }}
                placeholder={"_"}
            />
        </div>


    )
}
export default LanguageSwitcher