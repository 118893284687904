import ContactFormInput, {FormInput} from "./ContactFormInput";
import React from "react";

const ContactInputFormWithLabelAndConditions = ({contactFormInput, remarkInvalidAndEmpty}:{contactFormInput:FormInput, remarkInvalidAndEmpty: boolean}) =>{
    return (
        <div className={"flex flex-col sm:flex-row gap-2 w-full text-md sm:text-lg "}>
            <label className={"w-48 flex sm:justify-end font-bold"}>{contactFormInput.text}:</label>
            <div className={"text-black w-full "}>
                <div className={"w-full flex"}>
                    <ContactFormInput contactFormInput={contactFormInput} remarkInvalidAndEmpty={remarkInvalidAndEmpty}/>
                </div>
                <div
                    className={"w-full flex flex-col gap-1 mt-2 items-start text-mainTextLight dark:text-mainTextDark"}>
                    {contactFormInput.conditions.map((cond, i) => cond.value &&
                        <div className={"flex gap-1 items-center text-left"} key={i}>{cond.icon}{cond.text}</div>)}
                </div>
            </div>
        </div>
    )
}

export default ContactInputFormWithLabelAndConditions