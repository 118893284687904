import {useTranslation} from "react-i18next";
import {MutableRefObject} from "react";


const WorkExperience = () =>{
    const {t} = useTranslation();
    return(
            <div className={"w-full text-left"}>
                <a>{t('workExperience.content')}</a>
            </div>
    )
}

export default WorkExperience