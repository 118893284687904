import {useTranslation} from "react-i18next";
import {MutableRefObject} from "react";
import NestedList, {NestedListT} from "./common/NestedList";


const OtherData = () => {
    const {t} = useTranslation();
    const list:NestedListT = [
        t('otherData.content.location'),
        t('otherData.content.car'),
        t('otherData.content.english'),
        t('otherData.content.extraClass')
    ]

    return (

        <div className={"w-full text-left"}>
            <NestedList paraphs={list}/>
        </div>
    )
}

export default OtherData